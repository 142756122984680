import { useCallback, useRef, useState } from 'react';

export const useNavigationBar = () => {
  const [header, setHeader] = useState<string | undefined>(undefined);

  const handleSetHeader = useCallback(
    (pageHeader: string) => {
      setHeader(pageHeader);
    },
    [setHeader]
  );

  return { header, setHeader: handleSetHeader };
};
