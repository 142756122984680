import React, { useEffect, useState } from 'react';
import { useGameObject } from '../../../store/GameContext';
import ControllerTransitionOverlay from '../../../components/Transition/ControllerTransitionOverlay';
import PageWrapper from '../../../components/TemplateCommon/PageWrapper';
import { ScrollBubbles } from '../../../components/scroll-bubbles/ScrollBubbles';
import { ArrowsButton } from '../../../components/arrows-buttons/ArrowsButton';
import styles from './ControllerVoteDrawing.module.css';
import VoteStageLoader from '../../../assets/loaders/VoteStageLoader.webp';
import SwipeableViews from 'react-swipeable-views';
import { shuffleArray } from '@magicyard/blanksy-game/src/utils/common.util';
import CheckIcon from '../../../components/DrawingsView/assets/CheckIcon.webp';
import DrawingAlt from '@magicyard/blanksy-shared/components/DrawingAlt';
import { SingleCanvas } from '../../../components/single-canvas/SingleCanvas';
import { OptionalTraits, track } from '@magicyard/shared/src/analytics';
import { Drawing } from '@magicyard/blanksy-game/src/Types';

const PAGE_INFO = {
  dictionary: {
    title: 'Time to Vote',
  },
};

type VoteSelectedEvent = OptionalTraits & {
  actionSource: 'timer' | 'select';
};

const VOTE_SELECTED_EVENT_NAME = 'Vote Selected';

export const ControllerVoteDrawing = () => {
  const { playerID, G, moves } = useGameObject();
  const { roundDrawings, currentRoundNumber, colorPalettes, players } = G;
  const isTransitionOverlay = G.isTransition;
  const [currentActiveIndex, setCurrentActiveIndex] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  useEffect(() => {
    // When phase ends (and this unmounts), track if voted or not
    return () => {
      if (selectedIndex !== null) {
        return;
      }
      track(VOTE_SELECTED_EVENT_NAME, { actionSource: 'timer' } as VoteSelectedEvent);
    };
  }, [selectedIndex]);

  const drawings = players
    .map((player) => {
      const drawing = roundDrawings[currentRoundNumber][player.id];
      return {
        playerID: player.id,
        strokes: drawing !== undefined ? drawing.strokes : [],
        word: drawing !== undefined ? drawing.word : 'Did not draw',
        bounds:
          drawing !== undefined
            ? drawing.bounds
            : {
                maxX: 0,
                maxY: 0,
              },
      };
    })
    .filter((x) => x.playerID !== playerID);
  const onClick = (drawing: Drawing, index: number): void => {
    setSelectedIndex(index);
    setCurrentActiveIndex(index);
    track(VOTE_SELECTED_EVENT_NAME, { actionSource: 'select' });
    moves.s03_submitVote(drawing.playerID);
  };

  const [shuffledDrawings, setShuffledDrawings] = useState<Array<Drawing>>([]);
  useEffect(() => {
    const shuffled = shuffleArray<Drawing>(drawings);
    setShuffledDrawings(shuffled);
  }, [setShuffledDrawings]);

  return (
    <div className={styles['controller-vote-drawing_root']}>
      <ControllerTransitionOverlay image={VoteStageLoader} show={isTransitionOverlay} />
      <PageWrapper>
        <div className={styles['controller-vote-drawing_container']}>
          <SwipeableViews
            resistance={true}
            enableMouseEvents={true}
            style={{
              width: '70%',
              padding: '0 400px',
              overflowY: 'hidden',
              flexShrink: 1,
            }}
            onChangeIndex={setCurrentActiveIndex}
            index={currentActiveIndex}
          >
            {shuffledDrawings
              .filter((drawing) => drawing.playerID !== playerID)
              .map((drawing, index) => {
                return (
                  <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}
                    onClick={() => onClick(drawing, index)}
                    key={index}
                  >
                    <SingleCanvas
                      style={{
                        opacity: currentActiveIndex === index ? 1 : 0.8,
                        transition: '0.5s',
                        transform: `scale(${currentActiveIndex === index ? 1 : 0.8})`,
                      }}
                      playerID={drawing.playerID}
                    >
                      {selectedIndex === index ? <Badge /> : undefined}
                      <DrawingAlt
                        strokes={drawing.strokes}
                        tapeColor={G.colorPalettes[drawing.playerID].canvas}
                        bounds={drawing.bounds}
                        voteView={true}
                      />
                    </SingleCanvas>
                  </div>
                );
              })}
          </SwipeableViews>
          <div className={styles['controller-vote-drawing_scroll-bubble-container']}>
            <ScrollBubbles
              activeIndex={currentActiveIndex}
              selectedIndex={selectedIndex}
              length={players.length}
              onClick={setCurrentActiveIndex}
            />
          </div>

          <div className={styles['controller-vote-drawing_button-container']}>
            <ArrowsButton
              onLeft={() => setCurrentActiveIndex((x) => Math.max(0, x - 1))}
              onRight={() => setCurrentActiveIndex((x) => Math.min(shuffledDrawings.length - 1, x + 1))}
            />
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

const Badge = () => {
  return (
    <div className={`badge--icon`} style={{ top: 5, right: 5, zIndex: 300, position: 'absolute' }}>
      <div
        style={{
          minWidth: 70,
          minHeight: 70,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundImage: `url(${CheckIcon})`,
        }}
      />
    </div>
  );
};
