import { Profile } from '@magicyard/shared/platform/lib/api';
import React, { useEffect, useState } from 'react';
import styles from './YardAndDisplayScreen.module.css';
import { GameButton } from './game-button/GameButton';
import TakePicDialog from './TakePicDialog';
import CameraIcon from '../assets/icons/CameraIcon.webp';
import { track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { SubmittableInput } from './submittable-input/SubmittableInput';
import { Dialog, IconButton, Slide } from '@material-ui/core';
import Background from './Background/Background';
import Close from '@material-ui/icons/Close';
import { YardWithDisplayController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
// import 'share-api-polyfill';

const MIN_DEV_CLICKS = 5;

export const YardAndDisplayScreen = ({
  controller,
  onSubmitOnline,
  onSubmitLocal,
  onProfileUpdate,
  setDevState,
}: {
  controller: YardWithDisplayController;
  onSubmitOnline: (gameId: string) => void;
  onSubmitLocal: (gameId: string) => void;
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
  setDevState: (devState: boolean) => void;
}) => {
  const [isTakingPic, setIsTakingPic] = useState(false);
  const [devClickCount, setDevClickCount] = useState(0);
  const [isSwitchUp, setIsSwitchUp] = useState(false);
  const [highlightRequired, setHighlightRequired] = useState(false);

  const shouldShowDev = devClickCount > MIN_DEV_CLICKS;

  useEffect(() => {
    updateAnalyticsData({ yardId: controller.yard.id, controllerId: controller.profile.id });
  }, [controller]);

  useEffect(() => {
    track('Yard With Display Loaded');
  }, []);

  const handleCameraClick = () => {
    track('Selfie Button Clicked');
    setIsTakingPic(true);
  };

  const startPrivateGame = () => {
    track('Private Game Requested');
    onSubmitLocal('blanksy');
  };

  // const startOnlineGame = () => {
  //   track('Online Game Requested');
  //   onSubmitOnline('blanksy');
  // };

  // const handleInviteClick = () => {
  //   track('Invite Friends Clicked');
  //   void share({
  //     text: 'Lets play Blanksy together!',
  //     url: getYardInviteLink(controller.yard.id),
  //   });
  // };

  const handlePlayNowClicked = () => {
    track('Play Now Clicked');
    if (controller.yard.controllers.length < 3) {
      track('Play Now Denied');
      setHighlightRequired(true);
      setTimeout(() => {
        setHighlightRequired(false);
      }, 2000);
    } else if (window.confirm('Wait! Is everyone in your party shown on the TV?')) {
      track('Play Now Success');
      // controller.yard.controllers.length >= 3 ? startPrivateGame() : startOnlineGame();
      startPrivateGame();
    }
  };

  const toggleSwitch = () => {
    setIsSwitchUp((x) => {
      const next = !x;

      if (next) {
        setTimeout(() => {
          setIsSwitchUp(false);
        }, 2000);
      }
      return next;
    });
  };

  return (
    <>
      <div className={styles['yard-and-display_white-bg']} />
      <div className={styles['yard-and-display-screen-root']}>
        {shouldShowDev && <div className={styles['yard-and-display-test-text']}>DEV MODE</div>}
        <NameWithDialog name={controller.profile.name} onProfileUpdate={onProfileUpdate} />
        {/*{isEditingName ?*/}
        {/*  <SubmittableInput forceEnglish={false} defaultValue={controller.profile.name} onSubmit={(v) => {*/}
        {/*    if (v.length > 0) {*/}
        {/*      onProfileUpdate({ name: v });*/}
        {/*      updateAnalyticsData({ fullName: v });*/}
        {/*      track('Name changed');*/}
        {/*    }*/}
        {/*    setIsEditingName(false);*/}
        {/*  }} /> :*/}
        {/*  <div*/}
        {/*    className={styles["yard-and-display_subtitle"]}*/}
        {/*    onClick={() => setIsEditingName(true)}*/}
        {/*  >*/}
        {/*    {controller.profile.name}*/}
        {/*  </div>*/}
        {/*}*/}
        <div className={styles['yard-and-display_user-image-container']}>
          <TakePicDialog
            onTakePic={(data) => {
              onProfileUpdate({ avatarUrl: data ?? undefined });
              setIsTakingPic(false);
            }}
            onClose={() => setIsTakingPic(false)}
            open={isTakingPic}
          />
          <div
            onClick={handleCameraClick}
            style={{
              backgroundImage: `url(${controller.profile.avatarUrl})`,
            }}
            className={styles['yard-and-display-screen-picture-container']}
          />
          <div
            onClick={handleCameraClick}
            className={styles['yard-and-display_camera-icon']}
            style={{ backgroundImage: `url(${CameraIcon})` }}
          />
        </div>
        <div className={styles['yard-and-display_buttons-container']}>
          <div className={styles['yard-and-display_button-container']}>
            <GameButton text={'Play!'} onClick={handlePlayNowClicked} style={{ width: '80%' }} disabled={isSwitchUp} />
          </div>
        </div>
        <svg style={{ position: 'absolute', zIndex: -1 }}>
          <path id="MyPath" fill="none" stroke="transparent" d="m10,45c53,-50 88,7 88,10" />
          <path id="MyPath2" fill="none" stroke="transparent" d="m10,45c53,50 88,7 88,10" />
        </svg>
        <div
          className={`${styles['yard-and-display-screen_curved-text']} ${
            isSwitchUp ? styles['yard-and-display-screen_curved-text-anim'] : ''
          }`}
        >
          <svg viewBox="0 0 110 110">
            <text>
              <textPath fill={isSwitchUp ? '#db3fce' : '#959493'} href="#MyPath" startOffset={'25%'}>
                online
              </textPath>
            </text>
          </svg>
        </div>
        <div className={styles['yard-and-display-screen_switch-container']} onClick={toggleSwitch}>
          <div
            className={`${styles['yard-and-display-screen_switch']} ${
              isSwitchUp ? '' : styles['yard-and-display-screen_switch-flip']
            }`}
          />
          <div
            className={
              isSwitchUp
                ? styles['yard-and-display-screen_switch-shade']
                : styles['yard-and-display-screen_switch-shade-down']
            }
          />
        </div>
        <div
          className={`${styles['yard-and-display-screen_curved-text-bottom']} ${
            !isSwitchUp ? styles['yard-and-display-screen_curved-text-anim-bottom'] : ''
          }`}
        >
          <svg viewBox="0 0 110 110">
            <text>
              <textPath fill={isSwitchUp ? '#959493' : '#08c675'} href="#MyPath2" startOffset={'20%'}>
                private
              </textPath>
            </text>
          </svg>
        </div>

        <div
          className={`${styles['yard-and-display-screen_required']} ${
            highlightRequired && !isSwitchUp ? styles['yard-and-display-screen_required-anim'] : ''
          }`}
          style={{ color: isSwitchUp ? 'red' : controller.yard.controllers.length >= 3 ? '#08c675' : undefined }}
        >
          {isSwitchUp
            ? 'Online is coming soon!'
            : controller.yard.controllers.length >= 3
            ? ''
            : `Waiting for ${3 - controller.yard.controllers.length} more players`}
        </div>
        {shouldShowDev && (
          <div onClick={startPrivateGame} className={styles['yard-and-display-test-text']}>
            Dev start
          </div>
        )}

        <div
          className={styles['yard-and-display_hidden-dev-btn']}
          onClick={() =>
            setDevClickCount((c) => {
              if (c + 1 > MIN_DEV_CLICKS && c === MIN_DEV_CLICKS) {
                setDevState(true);
              }

              return c + 1;
            })
          }
        />
      </div>
    </>
  );
};

const NameWithDialog = ({
  name,
  onProfileUpdate,
}: {
  name: string;
  onProfileUpdate: (profile: Partial<Omit<Profile, 'id'>>) => void;
}) => {
  const [isEditingName, setIsEditingName] = useState(false);

  return (
    <>
      <div className={styles['yard-and-display_subtitle']} onClick={() => setIsEditingName(true)}>
        {name}
      </div>
      <Dialog
        open={isEditingName}
        onClose={() => setIsEditingName(false)}
        fullScreen
        TransitionComponent={Slide}
        TransitionProps={{
          // @ts-expect-error
          direction: 'up',
        }}
      >
        <Background>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <IconButton className={styles['take-pic-dialog-close-icon-button']} onClick={() => setIsEditingName(false)}>
              <Close />
            </IconButton>
            <div style={{ fontSize: 32 }}>Edit your name</div>
            <SubmittableInput
              forceEnglish={false}
              defaultValue={name}
              onSubmit={(v) => {
                if (v.length > 0) {
                  onProfileUpdate({ name: v });
                  updateAnalyticsData({ fullName: v });
                  track('Name changed');
                }
                setIsEditingName(false);
              }}
            />
          </div>
        </Background>
      </Dialog>
    </>
  );
};

// async function share(data: ShareData): Promise<void> {
//   (window.navigator as any).share(data, SHARE_POLYFILL_OPTIONS).catch((error: any) => {
//     console.error(error);
//   });
// }

// const SHARE_POLYFILL_OPTIONS = {
//   copy: true,
//   email: true,
//   print: true,
//   sms: true,
//   messenger: false,
//   facebook: false,
//   whatsapp: true,
//   twitter: true,
//   linkedin: false,
//   telegram: true,
//   skype: false,
//   pinterest: false,
// };
