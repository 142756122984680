import React from 'react';
import LeftArrow from './assets/LeftArrowPurple.webp';
import RightArrow from './assets/RightArrowPurple.webp';
import styles from './ArrowsButton.module.css';

interface ArrowButtonsProps {
  onLeft: () => void;
  onRight: () => void;
}

export const ArrowsButton = (props: ArrowButtonsProps) => {
  const { onLeft, onRight } = props;
  return (
    <div className={styles['arrows-buttons_root']}>
      <img onClick={onLeft} className={styles['arrows-buttons_button-img-left']} src={LeftArrow} />
      <img onClick={onRight} className={styles['arrows-buttons_button-img-right']} src={RightArrow} />
    </div>
  );
};
