import NewGameAction from '../../components/NewGameAction';
import React from 'react';
import { useGameObject } from '../../store/GameContext';
import { LoaderTemplate } from '../../components/loader-template/LoaderTemplate';
import LookAtTvRatLoader from '../../assets/loaders/LookAtTvRatLoader.webp';

export const GameEnd = (props: { onStartNewGame: () => void }) => {
  const { G } = useGameObject();
  const isTransition = G.isTransition;

  if (isTransition) {
    return <LoaderTemplate image={LookAtTvRatLoader} />;
  } else {
    return <NewGameAction onStartNewGame={props.onStartNewGame} />;
  }
};
