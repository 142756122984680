import React, { useEffect, useMemo } from 'react';
import { useGameObject } from '../store/GameContext';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import { RoundSetupPickWord } from './Stages/round-setup-pick-word/RoundSetupPickWord';
import GuessDrawing from './Stages/controller-guess-drawing-simultaneous/ControllerGuessDrawingSimultaneous';
import { ActiveDrawingSimultaneous } from './Stages/active-drawing-simultaneous/ActiveDrawingSimultaneous';
import RoundFinished from './Stages/round-finished/RoundFinished';
import ControllerVoteSummary from './Stages/ControllerVoteSummary';
import Background from '../components/Background/Background';
import { NavigationBar } from '../components/navigation-bar/NavigationBar';
import { assertNever } from '@magicyard/utils/typeUtils';
import { ControllerVoteDrawing } from './Stages/controller-vote-drawing/ControllerVoteDrawing';
import LookAtTvRatLoader from '../assets/loaders/LookAtTvRatLoader.webp';
import { LoaderTemplate } from '../components/loader-template/LoaderTemplate';
import { BoardPropTypes } from '@magicyard/blanksy-game/src/Types';
import { GameEnd } from './Stages/GameEnd';
import { getPhaseEventName, getTransitionPhaseEventName } from '@magicyard/blanksy-shared/analytics';
import { track, updateAnalyticsData } from '@magicyard/shared/src/analytics';

const LookAtScreen = () => {
  return <LoaderTemplate image={LookAtTvRatLoader} />;
};
const StageManager = (props: { onStartNewGame: () => void }) => {
  const { ctx, G, playerID, moves } = useGameObject();

  const stages: Record<Phases, () => JSX.Element> = useMemo(
    () => ({
      [Phases.Sync]: LookAtScreen,
      [Phases.Explainer]: () => (
        <LoaderTemplate image={LookAtTvRatLoader} skipText={{ text: 'Skip intro', moves: moves }} />
      ),
      [Phases.Initial]: RoundSetupPickWord,
      [Phases.First]: ActiveDrawingSimultaneous,
      [Phases.Second]: GuessDrawing,
      [Phases.Third]: ControllerVoteDrawing,
      [Phases.Fourth]: ControllerVoteSummary,
      [Phases.EndRound]: RoundFinished,
      [Phases.GameEnd]: () => <GameEnd onStartNewGame={props.onStartNewGame} />,
    }),
    [props.onStartNewGame]
  );

  const phase = ctx.phase as Phases;
  const Stage = stages[phase];
  const { currentRoundNumber, isTransition } = G;
  const title = getTitleForPhase(phase, G, playerID);

  useEffect(() => {
    updateAnalyticsData({ currentStage: phase, currentRound: currentRoundNumber });
    const eventName = isTransition ? getTransitionPhaseEventName(phase) : getPhaseEventName(phase);

    if (!eventName) {
      return;
    }

    track(eventName);
  }, [phase, currentRoundNumber, isTransition]);

  return (
    <Background>
      <NavigationBar header={title} />
      <Stage />
    </Background>
  );
};

const getTitleForPhase = (phase: Phases, G: BoardPropTypes, playerID: string): string => {
  switch (phase) {
    case Phases.Sync:
    case Phases.GameEnd:
    case Phases.Initial:
    case Phases.Fourth:
    case Phases.Explainer:
      return '';
    case Phases.First:
      return G.wordForPlayer[playerID] ?? '';
    case Phases.Second:
      return 'Time to Guess';
    case Phases.Third:
      return 'Time to Vote';
    case Phases.EndRound:
      return 'Your score';
    default:
      assertNever(phase);
  }
};

export default StageManager;
