type Difficulty = 'Hard' | 'Medium' | 'Easy';

const words: { [key in Difficulty]: Array<string> } = {
  Hard: [
    'Internet',
    'Tropical',
    'Homework',
    'Chestnut',
    'Aquarium',
    'Memorial',
    'Feminine',
    'Symmetry',
    'Medieval',
    'Dispatch',
    'Pinwheel',
    'Dumbbell',
    'Business',
    'Blizzard',
    'Purchase',
    'Comedian',
    'Facebook',
    'Mechanic',
    'Possible',
    'Whistle',
    'Homerun',
    'Bobsled',
    'Pilgram',
    'Bargain',
    'Ability',
    'College',
    'Suspect',
    'Outline',
    'Strength',
    'Division',
    'Language',
    'Discount',
    'Obstacle',
    'Affinity',
    'Mailman',
    'Creation',
    'Tolerant',
    'Chandelier',
    'Incredible',
    'Continental',
    'Commercial',
    'Optimistic',
    'Insistence',
    'Appreciate',
    'Geological',
    'Sufficient',
    'Disagreement',
    'Bureaucratic',
    'Relationship',
    'Conversation',
    'Hibernation',
    'Acquisition',
    'Independent',
    'Imagination',
    'Convenience',
    'Provincial',
    'Confusion',
    'Community',
    'Sprinkler',
    'Performer',
    'Evolution',
    'Snowball',
    'Applause',
    'Potential',
    'Craftsman',
    'Criticize',
    'Quicksand',
    'Unexpected',
    'Sleepover',
    'Detention',
    'Cardboard',
    'Carpenter',
    'Yardstick',
    'Lawnmower',
    'Leaflet',
    'Space',
    'Horse',
    'Catch',
    'Panel',
    'Stone',
    'Clog',
    'Snap',
    'Cabin',
    'Pride',
    'Lodge',
    'Squad',
    'Baker',
    'Chaos',
    'Basin',
    'Pitch',
    'Spell',
    'Creep',
    'Stuff',
    'Bang',
    'Pump',
    'Fame',
    'Tube',
    'Loan',
    'Sour',
    'Eel',
    'Wag',
    'Monk',
    'Deeo',
    'Kiwi',
    'Club',
    'Pose',
    'Burp',
    'Bail',
    'Lace',
    'Risk',
    'Grin',
    'Leak',
    'Crayon',
    'Bleach',
    'Boring',
    'Afraid',
    'Tender',
    'Outlet',
    'Sphere',
    'Course',
    'Embryo',
    'Graphic',
    'Provide',
    'Paradox',
    'Meaning',
    'Vicious',
    'Buckle',
    'Bruise',
    'Gesture',
    'Robbery',
    'Unrest',
    'Comfy',
    'Koala',
    'Panic',
    'Laugh',
    'Stork',
    'Crust',
    'Coach',
    'Unite',
    'Cramp',
    'Resign',
    'Period',
    'Silver',
    'Bitter',
    'Exceed',
    'Tissue',
    'Angry',
    'Shiver',
    'Survey',
  ],
  Medium: [
    'Bedbug',
    'Cruise',
    'Thread',
    'Circus',
    'Sponge',
    'Kitten',
    'Jacket',
    'Crumbs',
    'Barber',
    'Bonnet',
    'Nature',
    'Eraser',
    'Spring',
    'Season',
    'Profile',
    'Teacher',
    'Garbage',
    'Deposit',
    'Cushion',
    'Trainer',
    'Cricket',
    'Dragon',
    'Fiddle',
    'Puppet',
    'Reptile',
    'Channel',
    'Teepee',
    'Invite',
    'Jungle',
    'Desert',
    'Finish',
    'Ground',
    'Timber',
    'Sketch',
    'Bible',
    'Hippo',
    'Alarm',
    'Fossil',
    'Chime',
    'Whale',
    'Attack',
    'Unplug',
    'Beggar',
    'Report',
    'Blinds',
    'Peanut',
    'Zombie',
    'Gutter',
    'Moving',
    'Friend',
    'Farmer',
    'Resort',
    'Sailor',
    'Frozen',
    'Spongebob',
    'Injection',
    'Promotion',
    'Pantyhose',
    'Deodorant',
    'Spiderman',
    'Satellite',
    'Starfish',
    'Darkness',
    'Baguette',
    'Boundary',
    'Mattress',
    'Pacifier',
    'Sunscreen',
    'Firefighter',
    'Leprechaun',
    'Toothpaste',
    'Convertible',
    'Baby-Sitter',
    'Cheerleader',
    'Playground',
    'Snowflake',
    'Dashboard',
    'Highchair',
    'Background',
    'Electronic',
    'Fireworks',
    'Falling',
    'Ceiling',
    'Catalog',
    'Sunburn',
    'Sandbox',
    'Lobster',
    'Meeting',
    'Passage',
    'Gravity',
    'Thinker',
    'Theatre',
    'Capital',
    'Scratch',
    'Tourist',
    'Mosquito',
    'Burrito',
    'Shampoo',
    'Tricycle',
    'Dripping',
    'Birthday',
    'Curtain',
    'Shallow',
    'Doormat',
    'Tadpole',
    'Dolphin',
    'Fireman',
    'Baggage',
    'Pour',
    'Bake',
    'Glue',
    'Type',
    'Maze',
    'Slow',
    'Push',
    'Palm',
    'Path',
    'Drug',
    'Call',
    'Shop',
    'Bear',
    'Nemo',
    'Tusk',
    'Skip',
    'Duck',
    'Open',
    'Baby',
    'Pool',
    'Chef',
    'Frog',
    'Tutu',
    'Drip',
    'Cuff',
    'Spit',
    'Swim',
    'Dive',
    'Note',
    'Lawn',
    'Horn',
    'Base',
    'Chop',
    'Soup',
    'Wax',
    'Fog',
    'Hay',
    'Whip',
    'Drop',
    'Cook',
    'Snow',
    'Wash',
    'Coal',
    'Wall',
    'Lock',
    'View',
    'Oven',
    'Mile',
    'Tool',
    'Farm',
    'Deep',
    'Glow',
    'Sign',
    'Band',
    'Dance',
    'Purse',
    'Relax',
    'Robot',
    'Piano',
    'Close',
    'Theft',
    'Storm',
    'Punch',
    'Clean',
    'Leave',
    'Steak',
    'Thick',
    'Brain',
    'Thief',
    'Learn',
    'Photo',
    'Hotel',
    'Drive',
    'Skate',
    'Drink',
    'Nurse',
    'Badge',
    'Train',
    'Cliff',
    'Cloak',
    'State',
    'Pilot',
    'Tiger',
    'Bread',
    'Brake',
    'Noise',
    'Ocean',
    'Toast',
    'Giant',
    'Fluid',
    'Metal',
    'Dress',
    'Lunch',
    'Shout',
    'Award',
    'Climb',
    'Sweat',
    'Adult',
    'Juice',
    'Block',
    'Jewel',
    'Voice',
    'Shock',
    'Wagon',
    'Spray',
    'Chest',
    'Carry',
    'Speed',
  ],
  Easy: [
    'Tablet',
    'Castle',
    'Camera',
    'Cheese',
    'Beetle',
    'Flower',
    'Bikini',
    'Carpet',
    'Mobile',
    'Pencil',
    'Makeup',
    'Result',
    'Puzzle',
    'Tennis',
    'Closet',
    'Carrot',
    'Teapot',
    'Pocket',
    'Shower',
    'Hockey',
    'Cowboy',
    'Bubble',
    'Stairs',
    'Listen',
    'Bucket',
    'Basket',
    'Cookie',
    'Church',
    'Summer',
    'Frame',
    'Sleep',
    'Music',
    'Watch',
    'Darts',
    'Swing',
    'Queen',
    'Flute',
    'Night',
    'Child',
    'Write',
    'Light',
    'Money',
    'Trade',
    'Mouse',
    'Beard',
    'Panda',
    'Mouth',
    'Orange',
    'Shirt',
    'Chess',
    'Peace',
    'Beach',
    'Bride',
    'Igloo',
    'Truck',
    'Think',
    'World',
    'Multiply',
    'Baseball',
    'Lollipop',
    'Lipstick',
    'Gasoline',
    'Password',
    'Raindrop',
    'Pregnant',
    'Triangle',
    'Equation',
    'Download',
    'Superman',
    'Popsicle',
    'Olympics',
    'Toothbrush',
    'Dictionary',
    'Volleyball',
    'Photograph',
    'Application',
    'Electricity',
    'Lighthouse',
    'Megaphone',
    'Pineapple',
    'Calendar',
    'Butterfly',
    'Skateboard',
    'Strawberry',
    'Telescope',
    'Computer',
    'Parents',
    'Cutting',
    'Percent',
    'Charger',
    'America',
    'Minivan',
    'Avocado',
    'Batman',
    'Zipper',
    'Magnet',
    'Pirate',
    'Library',
    'Glasses',
    'Diamond',
    'Giraffe',
    'Captain',
    'Rainbow',
    'Battery',
    'Mailbox',
    'Biscuit',
    'Recycle',
    'Outside',
    'Mermaid',
    'Slipper',
    'Bicycle',
    'Pumpkin',
    'Vampire',
    'Cupcake',
    'Sport',
    'Egg',
    'Rug',
    'Rim',
    'Bus',
    'Plug',
    'Deer',
    'Ivy',
    'Fly',
    'Sun',
    'Hug',
    'Row',
    'Owl',
    'Wig',
    'Cat',
    'Seat',
    'Sing',
    'Body',
    'Taxi',
    'Bike',
    'Bark',
    'Star',
    'Milk',
    'Gift',
    'Fish',
    'Slot',
    'Bowl',
    'Wine',
    'Tail',
    'Eat',
    'Wet',
    'Fox',
    'Spy',
    'Win',
    'Bag',
    'Tip',
    'Sky',
    'Nap',
    'Cow',
    'Jaw',
    'Dog',
    'Lip',
    'Fat',
    'Man',
    'Run',
    'Tie',
    'Oar',
    'Far',
    'Mom',
    'Hot',
    'Ski',
    'Bar',
    'Toy',
    'Dig',
    'Ash',
    'Due',
    'Cut',
    'Buy',
    'Wifi',
    'Cold',
    'Knee',
    'Drum',
    'Face',
    'Shoe',
    'Bird',
    'Tire',
    'Crib',
    'Lion',
    'Love',
    'Coat',
    'Nose',
    'Jump',
    'Wheel',
    'Blade',
    'Blast',
    'Angle',
    'Point',
    'Cable',
    'Belly',
    'Flat',
    'Hair',
    'Pray',
    'Woman',
    'Lemon',
    'House',
    'Sound',
    'Honk',
    'Shot',
    'Rose',
    'Corn',
    'Seed',
    'Axis',
    'Game',
    'East',
    'Tear',
    'Sail',
    'Door',
    'Road',
    'Salt',
    'Flag',
    'Wire',
    'Text',
    'Read',
    'Lamp',
    'Book',
    'Park',
    'Cake',
    'Mask',
    'Size',
    'Ring',
    'Hook',
    'Leaf',
    'Ipad',
    'Miss',
    'Gate',
  ],
};

const getRandomWords = (n = 1, difficulty: Difficulty = 'Easy') => {
  const result = new Array<string>(n);
  let len = words[difficulty].length;
  const taken = new Array(len);
  if (n > len) throw new RangeError('getRandom: more elements taken than available');
  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = words[difficulty][x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

export default getRandomWords;
