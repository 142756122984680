import React, { useState } from 'react';
import { useGameObject } from '../../../store/GameContext';
import ControllerTransitionOverlay from '../../../components/Transition/ControllerTransitionOverlay';
import PageWrapper from '../../../components/TemplateCommon/PageWrapper';
import GuessingTimeRoundLoader from '../../../assets/loaders/GuessingTimeRoundLoader.webp';
import { AvatarWithPoints } from '../../../components/avatar-with-points/AvatarWithPoints';
import ArtEmoji from '../../../assets/emojis/ArtEmoji.webp';
import NerdEmoji from '../../../assets/emojis/NerdEmoji.webp';
import { StartPosition } from '../../../components/curved-text/CurvedText';
import styles from './ControllerGuessDrawingSimultaneous.module.css';
import { SubmittableInput } from '../../../components/submittable-input/SubmittableInput';
import { track } from '@magicyard/shared/src/analytics';
import { objectKeys } from '@magicyard/utils';

const sanitizeString = (str: string | undefined | null): string => (str ?? '').toUpperCase().trim();
const ControllerGuessDrawingSimultaneous = () => {
  const [guess, setGuess] = useState('');
  const { moves, G, playerID } = useGameObject();
  const [roundGuesses, setRoundGuesses] = useState<Array<string>>([]);
  const { wordForPlayer, guesses, players } = G;
  const isTransitionOverlay = G.isTransition;

  const [isGuessCorrect, setGuessColorIndication] = useState<boolean | undefined>(undefined);

  const correctGuesses = guesses[G.currentRoundNumber].filter(
    (g) => g.playerID === playerID && (g.reason === 'GUESS_FIRST' || g.reason === 'GUESS_REGULAR')
  );

  const othersWords = objectKeys(wordForPlayer).reduce<string[]>((acc, currPlayerId) => {
    if (playerID !== currPlayerId) {
      acc.push(sanitizeString(wordForPlayer[currPlayerId]));
    }

    return acc;
  }, []);

  const clearInput = () => {
    setGuessColorIndication(undefined);
    setGuess('');
  };
  const didPlayerAlreadyGuessThisWord = (currGuess: string): boolean =>
    G.guesses[G.currentRoundNumber].some(
      (guess) =>
        guess.word === currGuess &&
        guess.playerID === playerID &&
        (guess.reason === 'GUESS_FIRST' || guess.reason === 'GUESS_REGULAR')
    );

  const makeGuess = (guessWord: string | undefined) => {
    const curGuess = sanitizeString(guessWord ?? '');
    const isCorrect = othersWords.includes(curGuess) && !didPlayerAlreadyGuessThisWord(curGuess);
    track('Player Pressed Submit Guess', { guess: curGuess, isCorrect: isCorrect });
    if (isCorrect) {
      setGuessColorIndication(true);
      // All other guess + the new correct one
      if (correctGuesses.length + 1 === players.length - 1) {
        track('Player Submitted All Guesses Correctly');
      }
    } else {
      setGuessColorIndication(false);
    }

    setRoundGuesses([...roundGuesses, curGuess]);
    if (curGuess.length > 1) {
      moves.s02_guessWord(curGuess);
    }
    setTimeout(() => {
      clearInput();
    }, 500);
  };

  const userGuesses = () => {
    const correctGuesses = guesses[G.currentRoundNumber]
      .filter((g) => g.playerID === playerID && (g.reason === 'GUESS_FIRST' || g.reason === 'GUESS_REGULAR'))
      .map((guess) => guess.score);
    const opponentsCorrectGuesses = guesses[G.currentRoundNumber]
      .filter(
        (guess) => guess.playerID === playerID && (guess.reason === 'DRAWER_FIRST' || guess.reason === 'DRAWER_REGULAR')
      )
      .map((guess) => guess.score);

    return (
      <div className={styles['controller-guess-drawing-simultaneous_user-guesses']}>
        <div className={styles['controller-guess-drawing-simultaneous_card-container']}>
          <AvatarWithPoints
            isSummary={false}
            width={'small'}
            justifyContent={'flex-start'}
            key={'avatar-with-points-drawing1'}
            startPosition={StartPosition.left}
            image={ArtEmoji}
            color={'#AA4CA1'}
            header={'Drawing'}
            points={opponentsCorrectGuesses}
          />
        </div>
        <div className={styles['controller-guess-drawing-simultaneous_card-container']}>
          <AvatarWithPoints
            isSummary={false}
            width={'small'}
            justifyContent={'flex-start'}
            key={'avatar-with-points-2'}
            startPosition={StartPosition.right}
            image={NerdEmoji}
            color={'#008542'}
            header={'Guesses'}
            points={correctGuesses}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <ControllerTransitionOverlay image={GuessingTimeRoundLoader} show={isTransitionOverlay} />
      <PageWrapper classes="justify-space-between text-center">
        {userGuesses()}
        <div
          className={`${styles['d-flex']} ${styles['justify-center']} ${styles['align-center']} ${styles['flex-column']}`}
        >
          {correctGuesses.length === players.length - 1 ? (
            <div style={{ fontSize: 64, marginTop: 32 }}>Good job!</div>
          ) : (
            <SubmittableInput
              forceEnglish={true}
              value={guess}
              onChange={setGuess}
              isValid={isGuessCorrect}
              onSubmit={makeGuess}
            />
          )}
        </div>
      </PageWrapper>
    </>
  );
};

export default ControllerGuessDrawingSimultaneous;
