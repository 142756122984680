import React from 'react';

import styles from './NavigationBar.module.css';

export type NavigationBarProps = {
  header?: string;
};
export const NavigationBar = (props: NavigationBarProps) => {
  const { header } = props;
  return (
    <div className={styles['navigation-bar_root']}>
      <div className={styles['navigation-bar_content']}>
        <div className={styles['navigation-bar_header']}>{header}</div>
        {header === undefined || header.length === 0 ? <div style={{ height: 32 }} /> : undefined}
        {/*<div>*/}
        {/*  <BurgerIconSvg key={'burger-icon'} />;*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
