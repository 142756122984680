import React from 'react';
import RatShadeImage from '../../assets/loaders/RatShadeImage.webp';
import styles from './LoaderTemplate.module.css';
import { GameButton } from '../game-button/GameButton';

interface LoaderTemplateProps {
  image?: string;
  skipText?: { text: string; moves: any };
}

export const LoaderTemplate = (props: LoaderTemplateProps) => {
  const { image } = props;
  const sk = props.skipText;
  return (
    <div className={styles['loader-template_root']}>
      <div className={styles['loader-template_container']}>
        <img
          className={`${styles['loader-template-background-img']} ${styles['loader-template_shade']}`}
          src={RatShadeImage}
        />
        <img
          className={`${styles['loader-template-background-img']} ${styles['loader-template-background-img-content']}`}
          src={image}
        />
      </div>
      {sk !== undefined ? (
        <GameButton
          text={sk.text}
          onClick={() => {
            if (window.confirm('Are you sure?')) {
              sk.moves.skipPhase();
            }
          }}
          rootStyle={{ position: 'absolute', bottom: 0, height: 100 }}
        />
      ) : null}
    </div>
  );
};
