import React, { useCallback } from 'react';
import { GameButton } from './game-button/GameButton';
import { useGameObject } from '../store/GameContext';

const NewGameAction = (props: { onStartNewGame: () => void }) => {
  const { G, playerID } = useGameObject();
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <div style={{ fontSize: 34 }}>Score: {G.score[+playerID]}</div>
      <GameButton onClick={props.onStartNewGame} text={'Play Again!!'} />
    </div>
  );
};

export default NewGameAction;
