import WordSelectionBackground from '../../../../assets/WordSelectionBackground.webp';
import { GameButton } from '../../../../components/game-button/GameButton';
import React from 'react';
import LeftOneButtonBackground from '../../../../assets/LeftOneButton.webp';
import RightOneButtonBackground from '../../../../assets/RightOneButton.webp';
import LeftTwoButtonBackground from '../../../../assets/LeftTwoButton.webp';
import RightTwoButtonBackground from '../../../../assets/RightTwoButton.webp';
import RightThreeButtonBackground from '../../../../assets/RightThreeButton.webp';
import LeftThreeButtonBackground from '../../../../assets/LeftThreeButton.webp';
import RightFourButtonBackground from '../../../../assets/RightFourButton.webp';
import LeftFourButtonBackground from '../../../../assets/LeftFourButton.webp';
import styles from '../RoundSetupPickWord.module.css';

const BUTTON_BACKGROUNDS: Record<number, string> = {
  0: LeftOneButtonBackground,
  1: RightOneButtonBackground,
  2: LeftTwoButtonBackground,
  3: RightTwoButtonBackground,
  4: LeftThreeButtonBackground,
  5: RightThreeButtonBackground,
  6: LeftFourButtonBackground,
  7: RightFourButtonBackground,
};

interface WordSuggestionsBoxProps {
  wordSelection: Array<string>;
  onExit: () => void;
  onSelect: (sWords: string) => void;
}

export const WordSuggestionsBox = (props: WordSuggestionsBoxProps) => {
  const { wordSelection, onExit, onSelect } = props;
  return (
    <div
      className={styles['round-setup-pick-word_floating-words']}
      style={{ backgroundImage: `url(${WordSelectionBackground})` }}
    >
      <div className={styles['round-setup-pick-word_floating-words-content']}>
        <div className={styles['round-setup-pick-word_action-container']}>
          <div onClick={onExit} style={{ fontSize: 40, marginTop: -30 }}>
            X
          </div>
        </div>
        <div className={styles['round-setup-pick-word_button-grid']}>
          {wordSelection.map((sWord, index) => (
            <div key={sWord} className={styles['round-setup-pick-word_floating-words-button']}>
              <GameButton onClick={() => onSelect(sWord)} background={BUTTON_BACKGROUNDS[index]} text={sWord} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
