import React, { useEffect } from 'react';
import { useGameObject } from '../../../store/GameContext';
import NewGameAction from '../../../components/NewGameAction';
import PageWrapper from '../../../components/TemplateCommon/PageWrapper';
import { assertIsDefined } from '@magicyard/utils/typeUtils';
import { AvatarWithPoints } from '../../../components/avatar-with-points/AvatarWithPoints';
import ArtEmoji from '../../../assets/emojis/ArtEmoji.webp';
import LaughEmoji from '../../../assets/emojis/LaughEmoji.webp';
import NerdEmoji from '../../../assets/emojis/NerdEmoji.webp';
import { StartPosition } from '../../../components/curved-text/CurvedText';
import styles from './RoundFinished.module.css';
import WhiteBackground from './assets/WhiteBackground.webp';
import { OptionalTraits, track } from '@magicyard/shared/src/analytics';

const PAGE_INFO = {
  dictionary: {
    title: 'Your Score',
    secondary: '',
  },
};

const LABEL_TO_EMOJI = {
  GUESS_FIRST: NerdEmoji,
  FUNNIEST: LaughEmoji,
  DRAWER_FIRST: ArtEmoji,
};

type ScoreLoadedEventTraits = OptionalTraits & {
  type: string;
};

const ScoreEntry = ({
  entry,
}: {
  entry: { label: string; times: number; score: number; emoji?: string; color?: string | undefined };
}) => {
  const { label, times, color, score, emoji } = entry;

  useEffect(() => {
    if (!emoji || !label) {
      return;
    }
    track('Player Score Loaded', { type: label } as ScoreLoadedEventTraits);
  }, [emoji, label]);

  if (score === 0) {
    return <></>;
  }

  return (
    <div className={styles['round-finished_avatar-container']}>
      {emoji ? (
        <div className={styles['round-finished_avatar-avatar-container']}>
          <AvatarWithPoints
            isSummary={true}
            shouldCurveHeader={false}
            justifyContent={'space-between'}
            image={emoji}
            color={color}
            header={label}
            points={[score]}
            startPosition={StartPosition.top}
          />
        </div>
      ) : undefined}
    </div>
  );
};

const RoundFinished = () => {
  const { G, playerID } = useGameObject();
  const { guesses, scoreDeltas } = G;

  const playingScoreMap = guesses[G.currentRoundNumber]
    .filter((guess) => guess.playerID === playerID && guess.score > 0)
    .reduce(
      (acc, guess) => {
        acc[guess.reason].times += 1;
        acc[guess.reason].score += guess.score;
        acc.TOTAL.score += guess.score;
        return acc;
      },
      {
        GUESS_FIRST: {
          emoji: LABEL_TO_EMOJI['GUESS_FIRST'],
          color: '#008542',
          label: 'First Guess',
          times: 0,
          score: 0,
        },
        GUESS_REGULAR: {
          label: 'Correct Guesses',
          times: 0,
          score: 0,
        },
        DRAWER_FIRST: {
          emoji: LABEL_TO_EMOJI['DRAWER_FIRST'],
          label: 'Drawing (F)',
          color: '#AA4CA1',
          times: 0,
          score: 0,
        },
        DRAWER_REGULAR: {
          label: 'Drawing',
          times: 0,
          score: 0,
        },
        TOTAL: {
          score: 0,
        },
      }
    );

  assertIsDefined(scoreDeltas);
  const accDelta = playingScoreMap.TOTAL.score + scoreDeltas[+playerID];

  return (
    <>
      <PageWrapper>
        <div
          className={`${styles['d-flex']} ${styles['flex-column']} ${styles['align-center']}`}
          style={{ width: '100%' }}
        >
          <div
            style={{
              background: `url(${WhiteBackground})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              width: '86vw',
              height: '79vh',
              paddingTop: '10vh',
            }}
          >
            <div>
              <ScoreEntry entry={playingScoreMap.GUESS_FIRST} />
              <ScoreEntry entry={playingScoreMap.GUESS_REGULAR} />
              <ScoreEntry entry={playingScoreMap.DRAWER_FIRST} />
              <ScoreEntry entry={playingScoreMap.DRAWER_REGULAR} />
              <ScoreEntry
                entry={{
                  label: 'Funny',
                  color: '#60D7DB',
                  emoji: LABEL_TO_EMOJI['FUNNIEST'],
                  score: scoreDeltas[+playerID],
                  times: 1,
                }}
              />
            </div>
            {/*<div style={{fontSize: 56}}>*/}
            {/*    {roundsCount === 1 ? accDelta : `${score[+playerID] - accDelta} + ${accDelta} = ${score[+playerID]}`}*/}
            {/*</div>*/}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default RoundFinished;
