import React, { CSSProperties } from 'react';
import styles from './GameButton.module.css';
import ButtonBackground from '../../assets/ButtonBackground.webp';
import EnabledButtonBackground from '../../assets/EnabledButtonBackground.webp';

export interface GameButtonProps {
  disabled?: boolean;
  isGreen?: boolean;
  text: string;
  onClick: () => void;
  background?: string;
  style?: CSSProperties;
  rootStyle?: CSSProperties;
}

export const GameButton = (props: GameButtonProps) => {
  const { text, disabled, isGreen, onClick, style, background, rootStyle } = props;
  return (
    <div
      className={`${styles['game-button_root']} ${disabled ? styles['game-button_disabled'] : ''}`}
      style={rootStyle}
      onClick={() => !disabled && onClick()}
    >
      <div
        className={styles['game-button_background']}
        style={{
          ...(style || {}),
          backgroundImage: `url(${background ? background : !isGreen ? ButtonBackground : EnabledButtonBackground})`,
        }}
      />
      <div className={styles['game-button_text']}>{text}</div>
    </div>
  );
};
