import React, { useCallback, useEffect, useState } from 'react';
import { useGameObject } from '../../../store/GameContext';
import { Sketch } from '../../../components/Sketch/Sketch';
import ControllerTransitionOverlay from '../../../components/Transition/ControllerTransitionOverlay';
import SentencePuzzle from '../../../components/SentencePuzzle/SentencePuzzle';
import PageWrapper from '../../../components/TemplateCommon/PageWrapper';
import { DrawingBound, Stroke } from '@magicyard/blanksy-game/src/Types';
import styles from './ActiveDrawingSimultaneous.module.css';
import { useNavigationBar } from '../../../hooks/UseNavigationBar';
import LoaderStageThree from '../../../assets/loaders/LoaderStageThree.webp';
import { SingleCanvas } from '../../../components/single-canvas/SingleCanvas';
import { ToolbarCloud } from '../../../components/toolbar-cloud/ToolbarCloud';
import { OptionalTraits, track } from '@magicyard/shared/src/analytics';
import DrawingAlt from '@magicyard/blanksy-shared/components/DrawingAlt';

const PAGE_INFO = {
  dictionary: {
    title: 'Now Draw It!',
    done_title: 'Done!',
  },
};
export const DRAWING_STORAGE_KEY = 'drawingData';

type SubmitSource = 'button' | 'timer';
type DrawingSubmittedEvent = OptionalTraits & {
  isEmpty: boolean;
  actionSource: SubmitSource;
};
const DRAWING_EVENT_NAME = 'Drawing Submitted';

export const getStorageKeyForPlayer = (playerID: string) => {
  return DRAWING_STORAGE_KEY + playerID;
};

export const clearActiveDrawingStorage = (playerID: string) => {
  sessionStorage.removeItem(getStorageKeyForPlayer(playerID));
};

export const ActiveDrawingSimultaneous = () => {
  const { moves, G, playerID } = useGameObject();
  const [submittedDrawing, setSubmittedDrawing] = useState(false);
  const { setHeader } = useNavigationBar();
  const { wordForPlayer, roundDrawings, currentRoundNumber, colorPalettes, shouldForceSubmit } = G;
  const localStorageDataOfDrawing = sessionStorage.getItem(getStorageKeyForPlayer(playerID));

  if (G.shouldClearDrawingStorage[playerID]) {
    clearActiveDrawingStorage(playerID);
    moves.confirmClearStorage();
  }

  const myDrawing: Stroke[] =
    localStorageDataOfDrawing !== null
      ? (JSON.parse(localStorageDataOfDrawing) as Stroke[])
      : roundDrawings[currentRoundNumber][playerID]?.strokes ?? [];
  const drawingConfirmed = roundDrawings[currentRoundNumber][playerID] !== undefined;

  const isTransition = G.isTransition;

  const colors = colorPalettes[playerID].palette.colors;

  useEffect(() => {
    setHeader(submittedDrawing ? PAGE_INFO.dictionary.done_title : PAGE_INFO.dictionary.title);
  }, [setHeader, submittedDrawing]);
  useEffect(() => {
    setSubmittedDrawing(drawingConfirmed);
  }, [drawingConfirmed]);

  const submitDrawing = useCallback(
    (drawingStrokes: Stroke[], bounds: DrawingBound, actionSource: SubmitSource) => {
      track(DRAWING_EVENT_NAME, { isEmpty: drawingStrokes.length === 0, actionSource: actionSource });
      moves.s01_submitDrawing(drawingStrokes, bounds);
      setSubmittedDrawing(true);
      clearActiveDrawingStorage(playerID);
    },
    [moves, playerID]
  );

  return (
    <>
      <ControllerTransitionOverlay show={isTransition} image={LoaderStageThree} />
      <PageWrapper>
        <div className={`${styles['d-flex']} ${styles['fill-height']} ${styles['w-100']} ${styles['mb-5']}`}>
          <div
            className={`${styles['flex']} ${styles['d-flex']} ${styles['flex-column']} ${styles['fill-height']} ${styles['position-relative']} ${styles['w-100']}`}
          >
            <div className={styles['active-drawing-simultaneous_content-container']}>
              {submittedDrawing ? (
                <SingleCanvas playerID={playerID}>
                  <DrawingAlt
                    strokes={myDrawing}
                    tapeColor={G.colorPalettes[playerID].canvas}
                    bounds={roundDrawings[currentRoundNumber][playerID]?.bounds ?? { maxX: 0, maxY: 0 }}
                  />
                </SingleCanvas>
              ) : (
                <Sketch
                  playerColorPalettes={colorPalettes[playerID]}
                  moves={moves}
                  forceSubmit={G.shouldForceSubmit}
                  onSubmit={submitDrawing}
                  colorPalettes={colors}
                  initialState={myDrawing}
                  storageKey={getStorageKeyForPlayer(playerID)}
                />
              )}
            </div>
            {submittedDrawing ? (
              <ToolbarCloud>
                <div className={styles['active-drawing-simultaneous_sentence-puzzle-summary-container']}>
                  <SentencePuzzle
                    sentence={G.cardSentences[G.currentRoundNumber].content}
                    word={wordForPlayer[playerID] ?? ''}
                    isVisibleWord={true}
                    isView={true}
                  />
                </div>
              </ToolbarCloud>
            ) : undefined}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
