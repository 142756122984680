import Background from '../Background/Background';
import React from 'react';
import styles from '../../GameManager/Game.module.css';
import { LoaderTemplate } from '../loader-template/LoaderTemplate';

const ControllerTransitionOverlay = ({ show = false, children = undefined, image = '' }) => {
  if (!show) return <></>;
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1001 }}>
      <Background>
        <LoaderTemplate image={image} />
      </Background>
      {children}
    </div>
  );
};

export default ControllerTransitionOverlay;
