import React, { useEffect } from 'react';
import { updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { BoardPropsExtended } from '@magicyard/blanksy-game/src/Game';

import GameManager from '../GameManager/GameManager';
import GameContext from '../store/GameContext';

type BoardProps = {
  G: BoardPropsExtended;
  onStartNewGame: () => void;
};

const Board = ({ G, onStartNewGame }: BoardProps) => {
  const { matchID } = G;

  useEffect(() => {
    updateAnalyticsData({ matchId: matchID });
  }, [matchID]);
  return (
    <GameContext.Provider value={G}>
      <GameManager onStartNewGame={onStartNewGame} />
    </GameContext.Provider>
  );
};

export default Board;
