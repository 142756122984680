import { ScanQRDialog } from './components/ScanQrCode';
import React, { useState } from 'react';
import { GameButton } from './components/game-button/GameButton';

export const ScanDialogHelper = ({
  onScanDisplay,
  buttonText,
}: {
  buttonText: string;
  onScanDisplay: (url: string) => void;
}) => {
  const [scanDialogOpen, setScanDialogOpen] = useState<boolean>(false);
  return (
    <>
      <GameButton text={buttonText} onClick={() => setScanDialogOpen(true)} />

      {/*<ScanQRDialog open={scanDialogOpen} onClose={() => setScanDialogOpen(false)} onQRCode={onScanDisplay} />*/}
    </>
  );
};
