import React from 'react';
import { ScanDialogHelper } from './ScanDialogHelper';
import { YardController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';

export const YardScreen = ({
  onScanDisplay,
  controller,
}: {
  onScanDisplay: (url: string) => void;
  controller: YardController;
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: 32, fontSize: 24 }}>Your friends are here!</div>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
          {controller.yard.controllers
            .filter((x) => x.profile.id !== controller.profile.id)
            .map((c) => (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} key={c.profile.id}>
                <div
                  style={{
                    backgroundImage: `url(${c.profile.avatarUrl})`,
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                  }}
                />
                {c.profile.name}
              </div>
            ))}
        </div>
      </div>
      <ScanDialogHelper onScanDisplay={onScanDisplay} buttonText={'Scan to join them!'} />
    </div>
  );
};
