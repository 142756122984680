import React, { useEffect, useState } from 'react';
import styles from './AvatarWithPoints.module.css';
import { CurvedText, StartPosition } from '../curved-text/CurvedText';
import { assertNever } from '@magicyard/utils/typeUtils';

interface AvatarWithPointsProps {
  image: string;
  header: string;
  startPosition: StartPosition;
  color: string | undefined;
  isSummary: boolean;
  shouldCurveHeader?: boolean;
  points?: Array<number>;
  justifyContent: 'space-between' | 'flex-start';
  width?: 'small' | 'big';
}

export const AvatarWithPoints = (props: AvatarWithPointsProps) => {
  const { image, startPosition, isSummary, header, color, points, justifyContent } = props;

  const resolveLocation = () => {
    switch (startPosition) {
      case StartPosition.random:
        const randomSpots = Math.floor(Math.random() * 50) * 50;
        return { top: randomSpots, right: randomSpots };
      case StartPosition.top:
        return { top: '-30px', right: '50%' };
      case StartPosition.left:
        return { top: '-25%', left: '50%' };
      case StartPosition.right:
        return { top: -15, right: '50%' };
      case StartPosition.bottom:
        return { top: 0 };
      default:
        assertNever(startPosition);
    }
  };
  return (
    <div className={styles['avatar-with-points_root']} style={{ justifyContent: justifyContent }}>
      <div className={styles['avatar-with-points_content-container']}>
        <div className={styles['avatar-with-points_header-container']} style={resolveLocation()}>
          <CurvedText word={header} startPosition={startPosition} color={color || 'black'} shouldCurve={true} />
        </div>
        <div className={styles['avatar-with-points_image-container']}>
          <img src={image} className={styles['avatar-with-points_image']} />
        </div>
      </div>
      <div className={styles['avatar-with-points_guesses-container']}>
        {points?.length
          ? points.map((point, index) => (
              <div
                className={isSummary ? styles['avatar-with-points_summary-vote'] : styles['avatar-with-points_guesses']}
                key={index}
              >
                <CurvedText
                  color={color || 'black'}
                  shouldCurve={false}
                  startPosition={StartPosition.random}
                  word={`+${point} ${isSummary ? ' pt' : ''}`}
                />
              </div>
            ))
          : undefined}
      </div>
    </div>
  );
};

const CurvedHeader = ({ text = 'asdf', objectSize = 120, spacing = 12, offset = 30, overlap = false }) => {
  const d = objectSize + spacing * 2;
  const r = objectSize / 2 + spacing / 2;
  // const CurvedText = styled.div`
  //     // margin-bottom: ${overlap ? `-${r}px` : '0'};
  //     //width: ${d + offset * 2}px;
  //   //height: ${r + offset}px;
  //
  //   path {
  //     fill: transparent;
  //   }
  //
  //   text {
  //     fill: currentColor;
  //     text-anchor: middle;
  //   }
  // `;

  return (
    <div
      style={{
        marginBottom: overlap ? `-${r}px` : '0',
        width: `${d + offset * 2}px`,
        height: `${r + offset}px`,
        // path: {
        //   fill: transparent,
        // },
        //
        // text: {
        //   fill: currentColor,
        //   textAnchor: middle,
        // },
      }}
      className="curved-text"
    >
      <svg viewBox={`0 0 ${d + offset * 0.2} ${r + offset * 0.2}`}>
        <path id="curve" d={`M${offset},${r + offset} A${r},${r} 0 0,1 ${d + offset},${r + offset}`} />
        <text width="500">
          <textPath xlinkHref="#curve" startOffset="50%">
            {text}
          </textPath>
        </text>
      </svg>
    </div>
  );
};
