import React from 'react';
import styles from './CurvedText.module.css';
import { assertNever } from '@magicyard/utils/typeUtils';

export enum StartPosition {
  top = 'top',
  left = 'left',
  right = 'right',
  bottom = 'bottom',
  random = 'random',
}

interface CurvedTextProps {
  word: string;
  startPosition: StartPosition;
  color: string;
  shouldCurve?: boolean;
}

export const CurvedText = ({ word, startPosition, color, shouldCurve }: CurvedTextProps) => {
  const finalWord: Array<any> = [];
  const resolveLocation = () => {
    //return starting angle
    switch (startPosition) {
      case StartPosition.random:
        return 4;

      case StartPosition.top:
        return -40;
      case StartPosition.left:
        return -80;
      case StartPosition.right:
        return 0;
      case StartPosition.bottom:
        return 60;
      default:
        assertNever(startPosition);
    }
  };
  for (let i = 0; i <= word.length; i++) {
    finalWord.push(
      <span
        key={i}
        className={`${shouldCurve ? styles['curved-text_root'] : styles['curved-text_non-root']} ${
          styles['curved-text_text']
        }`}
        style={{
          transform: shouldCurve ? `rotate(${11 * i + resolveLocation()}deg)` : undefined,
          color: color,
          fontFamily: !shouldCurve ? 'cityburnregular, sans-serif' : 'BebasNeueRegular, sans-serif',
        }}
      >
        {word[i]}
      </span>
    );
  }
  return <div>{finalWord}</div>;
};
