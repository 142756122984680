import React from 'react';
import styles from './ColorPicker.module.css';
import { ColorButtonItem } from './Toolbar';
import ColorPickerBackground from './assets/ColorPickerBackground.webp';

const COLORS = [
  '#FFF',
  '#FFF200',
  '#8DC63F',
  '#F49AC1',
  '#ACACAC',
  '#F26522',
  '#00A651',
  '#EC008C',
  '#555555',
  '#FF0000',
  '#00AEEF',
  '#92278F',
  '#0054A6',
  '#440E62',
];

interface ColorPickerProps {
  colors: Array<string>;
  activeColor: string;
  isOpen: boolean;
  onClick: (color: string) => void;
}

export const ColorPicker = (props: ColorPickerProps) => {
  const { colors, activeColor, onClick, isOpen } = props;
  return (
    <div className={styles['color-picker_root']} style={{ backgroundImage: `url(${ColorPickerBackground})` }}>
      <div className={styles['color-picker_grid']}>
        {isOpen &&
          [...COLORS, ...colors].map((pColor, index) => (
            <ColorButtonItem
              key={pColor}
              isActive={activeColor === pColor}
              color={pColor}
              onClick={() => onClick(pColor)}
              animationIndex={index}
            />
          ))}
      </div>
      <div style={{ position: 'absolute', bottom: 4 }}>
        <ColorButtonItem
          key={`${activeColor}-active-color`}
          isActive={true}
          color={activeColor}
          onClick={() => onClick(activeColor)}
        />
      </div>
    </div>
  );
};
