import React from 'react';

const InputMasked = ({ word = '', isVisible = false, input = '', isView = false }) => {
  const WordMask = () => {
    const wordLengthArray = new Array(word.length).fill('');

    return (
      <>
        {wordLengthArray.map((x, idx) =>
          word[idx] === ' ' ? (
            <span key={`space-${idx}`} className={'text--red'}>
              {input[idx] || '\u00A0\u00A0'}
            </span>
          ) : (
            <span className={'border-bottom-1 d-inline-block mx-2'} key={`dash-${idx}`} style={{ minWidth: 15 }}>
              {input[idx] || '\u00A0'}
            </span>
          )
        )}
      </>
    );
  };

  return (
    <>
      <div>
        {word ? (
          isVisible ? (
            <span
              className={`${
                isView ? 'text--light-green' : 'border-bottom-1'
              } font-weight-bold d-inline-block text-center`}
              // style={{ minWidth: 120 }}
            >
              {word}
            </span>
          ) : (
            <WordMask />
          )
        ) : (
          <span className={'border-bottom-1 d-inline-block'} style={{ minWidth: 120 }}>
            {input || '\u00A0'}
          </span>
        )}
      </div>
    </>
  );
};

export default InputMasked;
