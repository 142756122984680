import React from 'react';
import DotIcon1 from './assets/dots.webp';
import DotIcon2 from './assets/dots2.webp';
import DotIcon3 from './assets/dots3.webp';
import DotIcon4 from './assets/dots4.webp';
import DotIcon5 from './assets/dots5.webp';
import ActiveDot from './assets/ActiveDot.webp';
import CheckmarkIcon from './assets/CheckmarkIcon.webp';
import styles from './ScrollBubbles.module.css';

export interface ScrollBubbleProps {
  length: number;
  activeIndex: number;
  selectedIndex: number | null;
  onClick: (index: number) => void;
}

export const ScrollBubbles = (props: ScrollBubbleProps) => {
  const { activeIndex, length, selectedIndex } = props;
  let dots = [DotIcon1, DotIcon2, DotIcon3, DotIcon4, DotIcon5].slice(0, length - 1).map((img, index) => {
    return (
      <div
        className={styles['scroll-bubbles_active-bubble-container']}
        onClick={() => props.onClick(index)}
        key={index}
      >
        {selectedIndex === index ? (
          <div
            style={{ backgroundImage: `url(${CheckmarkIcon})` }}
            className={styles['scroll-bubbles_checkmark']}
            key={'scroll-tick-' + index}
          />
        ) : undefined}
        <div
          className={`${styles['scroll-bubbles_img']} ${
            activeIndex === index ? styles['scroll-bubbles_img-active'] : ''
          }`}
          style={{ backgroundImage: `url(${activeIndex === index ? ActiveDot : img})` }}
        />
      </div>
    );
  });
  return <div className={styles['scroll-bubbles_root']}>{dots}</div>;
};
