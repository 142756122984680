import ToolBarBrushBackground from '../Sketch/assets/ToolbarBrush.webp';
import React from 'react';
import styles from './ToolbarCloud.module.css';

interface ToolbarCloudProps {
  children: React.ReactNode;
}

export const ToolbarCloud = (props: ToolbarCloudProps) => {
  const { children } = props;
  return (
    <div
      style={{ backgroundImage: `url(${ToolBarBrushBackground})` }}
      className={`${styles['active-drawing-simultaneous_sentence-puzzle-container']} ${styles['sketch_toolbar-container']}`}
    >
      {children}
    </div>
  );
};
