import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DrawingBoard } from './drawing-board/DrawingBoard';
import { Toolbar } from './Toolbar';
import MovesUtil from '@magicyard/blanksy-game/src/utils/moves.util';
import { ColorPalette, DrawingBound, Point, Stroke } from '@magicyard/blanksy-game/src/Types';
import styles from './sketch.module.css';
import { SingleCanvas } from '../single-canvas/SingleCanvas';
import BinIcon from '../../assets/BinIcon.webp';
import { ToolbarCloud } from '../toolbar-cloud/ToolbarCloud';
import { useGameObject } from '../../store/GameContext';
import { track } from '@magicyard/shared/src/analytics';

export type SketchProps = {
  moves: { [key in keyof typeof MovesUtil]: (...args: any) => void };
  onSubmit?: (strokes: Stroke[], bound: DrawingBound, actionSource: 'button' | 'timer') => void;
  colorPalettes?: string[];
  initialState: Stroke[];
  playerColorPalettes: ColorPalette;
  storageKey: string;
  forceSubmit: boolean;
};

export const Sketch = (props: SketchProps) => {
  const { playerID, G } = useGameObject();
  const { moves, onSubmit = () => undefined, colorPalettes = ['green', 'orange', 'purple'] } = props;
  const [currentColor, setCurrentColor] = useState(colorPalettes[0]);
  const [currentWidth, setCurrentWidth] = useState(5.2);
  // Initial state is used in onReady
  const [lines, setLines] = useState<Stroke[]>(props.initialState);
  const [bounds, setBounds] = useState<DrawingBound>({ maxX: 0, maxY: 0 });
  const [isShowClearPopup, setIsShowClearPopup] = useState(false);
  const defaultLines = useRef<Stroke[]>([]);

  const handleClear = () => {
    setLines(defaultLines.current);
    track('Sketch Delete Clicked');
    sessionStorage.setItem(props.storageKey, JSON.stringify([]));
  };

  const handleSubmit = () => {
    onSubmit(lines, bounds, 'button');
  };

  useEffect(() => {
    if (props.forceSubmit) {
      onSubmit(lines, bounds, 'timer');
    }
  }, [bounds, lines, onSubmit, props.forceSubmit]);
  const handleUndo = () => {
    const newLines = [...lines];
    newLines.pop();
    if (newLines.length === 0) {
      setLines(defaultLines.current);
    } else {
      setLines(newLines);
    }
    track('Sketch Undo Clicked');
    sessionStorage.setItem(props.storageKey, JSON.stringify(newLines));
  };

  const onBoundingRectChange = useCallback((drawingPaper: HTMLDivElement) => {
    const boundingRect = drawingPaper.getBoundingClientRect();
    setBounds({ maxX: boundingRect.width, maxY: boundingRect.height });
  }, []);

  useEffect(() => {
    if (bounds.maxY !== 0 && bounds.maxX !== 0) {
      setLines((l) => clampStrokes(l, bounds.maxX, bounds.maxY));
    }
  }, [bounds.maxX, bounds.maxY, props.initialState]);

  const clampStrokes = (strokes: Stroke[], maxX: number, maxY: number) =>
    strokes.map((stroke) => {
      const points = stroke.points.reduce<Point[]>((acc2, point) => {
        if (point.x <= maxX && point.y <= maxY) {
          acc2.push(point);
        }
        return acc2;
      }, []);
      return {
        color: stroke.color,
        points: points,
        width: stroke.width,
      };
    });

  return (
    <div className={styles['sketch_root']}>
      <div className={styles['sketch_drawing-single-canvas-container']}>
        <SingleCanvas playerID={playerID}>
          <div className={styles['sketch_bin-icon-container']}>
            <img src={BinIcon} onClick={() => setIsShowClearPopup(true)} className={styles['sketch_bin-icon']} />
            {isShowClearPopup && (
              <div className={styles['sketch_bin-popup-container']} onClick={() => setIsShowClearPopup(false)}>
                <div className={styles['sketch_bin-popup']} onClick={handleClear}>
                  <div className={styles['sketch_bin-icon-open']} />
                  <div className={styles['sketch_red-bg']}>
                    <div className={styles['sketch_red-bg-text']}>Yes</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <DrawingBoard
            bounds={bounds}
            lines={lines}
            onBoundingRectChange={onBoundingRectChange}
            setLines={setLines}
            moves={moves}
            currentColor={currentColor}
            currentWidth={currentWidth}
            storageKey={props.storageKey}
          />
        </SingleCanvas>
      </div>

      <ToolbarCloud>
        <Toolbar
          colors={colorPalettes}
          color={currentColor}
          width={currentWidth}
          handleColor={(currColor: string) => setCurrentColor(currColor)}
          handleWidth={(currWidth: number) => setCurrentWidth(currWidth)}
          handleSubmit={handleSubmit}
          handleUndo={handleUndo}
        />
      </ToolbarCloud>
    </div>
  );
};
