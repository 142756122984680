import React from 'react';
import styles from './Background.module.css';
import BackgroundImage from '../../assets/BackgroundImage.webp';

export type BackgroundProps = {
  className?: string;
  children?: React.ReactNode;
  image?: any;
};

export const Background = ({ children }: BackgroundProps) => {
  return (
    <div className={styles['background_root']} style={{ backgroundImage: `url(${BackgroundImage})` }}>
      {children}
    </div>
  );
};

export default Background;
