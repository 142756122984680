import React from 'react';
import { ToggleFullScreenButton } from './components/ToggleFullScreenButton';
import styles from './AppBody.module.css';

export const AppBody = ({ title, children }: { title: string | undefined; children: React.ReactNode | undefined }) => {
  return (
    <>
      {/*<OrientationMessage />*/}
      <ToggleFullScreenButton />
      <div className={styles['app-body-container']}>
        <div className={styles['app-title']}>{title}</div>
        {children}
      </div>
    </>
  );
};
