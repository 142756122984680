import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import simplify from 'simplify-js';
import { touchToPoint } from '../utils';
import { DrawingBound, Point, Stroke } from '@magicyard/blanksy-game/src/Types';
import MovesUtil from '@magicyard/blanksy-game/src/utils/moves.util';
import styles from './DrawingBoard.module.css';
import { LinePath } from '@magicyard/blanksy-shared/components/LinePath';
import { useGameObject } from '../../../store/GameContext';
import { tapeColorToDimensions } from '@magicyard/blanksy-shared/components/DrawingAlt';

interface DrawingBoardProps {
  lines: Stroke[];
  currentColor: string;
  currentWidth: number;
  onBoundingRectChange: (drawingPaper: HTMLDivElement) => void;
  setLines: (newLines: Stroke[]) => void;
  moves: { [key in keyof typeof MovesUtil]: (...args: any) => void };
  storageKey: string;
  bounds: DrawingBound;
}

export const DrawingBoard = ({
  lines,
  currentColor,
  currentWidth,
  onBoundingRectChange,
  setLines,
  storageKey,
  bounds,
}: DrawingBoardProps) => {
  const svgRef = useRef<HTMLDivElement>(null);
  const [ptData, setPtData] = useState<Point[]>([]);
  const sendToServer = useCallback(() => {
    const lastStorage = sessionStorage.getItem(storageKey);
    if (lastStorage !== null) {
      const strokes = JSON.parse(lastStorage) as Stroke[];
      setLines(strokes);
    }
  }, [setLines, storageKey]);
  const { G, playerID } = useGameObject();

  useLayoutEffect(() => {
    const updateBounds = () => {
      if (svgRef.current !== null) {
        onBoundingRectChange(svgRef.current);
      }
    };

    window.addEventListener('resize', updateBounds);
    updateBounds();
    return () => {
      window.removeEventListener('resize', updateBounds);
    };
  }, [onBoundingRectChange]);

  let currentLine: React.ReactNode = <></>;
  if (ptData.length > 0) {
    const l: Stroke = {
      points: ptData,
      color: currentColor,
      width: currentWidth,
    };
    currentLine = <LinePath line={l} />;
  }

  const updateRealTime = useCallback(
    (lines: Stroke[], nextData: Point[]) => {
      const line: Stroke = {
        points: simplify(nextData),
        color: currentColor,
        width: currentWidth,
      };
      const newState = lines.concat(line);
      sessionStorage.setItem(storageKey, JSON.stringify(newState));
    },
    [currentColor, currentWidth, storageKey]
  );

  const onTouchMove = useCallback(
    (event: React.TouchEvent) => {
      if (svgRef.current !== null) {
        const { x, y } = svgRef.current.getBoundingClientRect();
        const point = touchToPoint(event, { x, y }, bounds);
        const newPtData = ptData.concat(point);
        setPtData(newPtData);
        updateRealTime(lines, newPtData);
      }
    },
    [bounds, lines, ptData, updateRealTime]
  );

  const onTouchStart = useCallback(
    (event: React.TouchEvent) => {
      event.stopPropagation();
      if (svgRef.current !== null) {
        const { x, y } = svgRef.current.getBoundingClientRect();
        const point = touchToPoint(event, { x, y }, bounds);

        // The initial point is entered twice (otherwise it doesn't show as a point, but gets removed)
        const duplicatedPointToShowAsLine = [point, point];
        setPtData(duplicatedPointToShowAsLine);
        updateRealTime(lines, duplicatedPointToShowAsLine);
      }
    },
    [bounds, lines, updateRealTime]
  );

  const onTouchEnd = useCallback(() => {
    setPtData([]);
    sendToServer();
  }, [sendToServer]);
  return (
    <div
      ref={svgRef}
      className={styles['drawing-board_root']}
      style={tapeColorToDimensions(G.colorPalettes[playerID].canvas)}
    >
      <svg
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          maxWidth: '100%',
        }}
        viewBox={`0,0,${bounds.maxX},${bounds.maxY}`}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        {lines.map((l: Stroke, i: number) => (
          <LinePath line={l} key={i} />
        ))}
        {currentLine}
      </svg>
    </div>
  );
};
