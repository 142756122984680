import React from 'react';
import BrushPopoverBackground from './asetts/BrushPopoverBackground.png';
import styles from './BrushesPopover.module.css';
import BrushIcon from '../assets/BrushIcon.webp';
import { objectKeys } from '@magicyard/utils';

enum BrushTypes {
  xl = 12,
  large = 9.4,
  medium = 6.8,
  small = 4.2,
}

interface BrushesPopoverProps {
  onClick: (size: number | undefined) => void;
}

export const BrushesPopover = (props: BrushesPopoverProps) => {
  const { onClick } = props;
  const brushesIcons = objectKeys(BrushTypes).map((k, i) => (
    <span
      key={k}
      onClick={() => onClick(BrushTypes[k])}
      style={{
        height: `${BrushTypes[k]}px`,
        marginBottom: `${BrushTypes[k] + 10}px`,
        animationDelay: i * 0.1 + 0.05 + 's',
      }}
      className={styles['brush_line']}
    />
  ));
  return (
    <div style={{ backgroundImage: `url(${BrushPopoverBackground})` }} className={styles['brush-popover_root']}>
      <div className={styles['brush-popover_icons-container']}>{brushesIcons}</div>
      <div className={styles['brush-popover_icon-container']}>
        <img width={'30px'} src={BrushIcon} alt="" onClick={() => onClick(undefined)} />
      </div>
    </div>
  );
};
