import React, { CSSProperties } from 'react';
import styles from './SingleCanvas.module.css';
import GreenCanvas from '../DrawingsView/assets/GreenCanvas.webp';
import BlueCanvas from '../DrawingsView/assets/BlueCanvas.webp';
import OrangeCanvas from '../DrawingsView/assets/OrangeCanvas.webp';
import PinkCanvas from '../DrawingsView/assets/PinkCanvas.webp';
import YellowCanvas from '../DrawingsView/assets/YellowCanvas.webp';
import { assertNever } from '@magicyard/utils/typeUtils';
import { useGameObject } from '../../store/GameContext';

const tapeColorToImageUrl = (tapeColor: 'blue' | 'orange' | 'red' | 'yellow' | 'green') => {
  switch (tapeColor) {
    case 'blue':
      return BlueCanvas;
    case 'orange':
      return OrangeCanvas;
    case 'red':
      return PinkCanvas;
    case 'yellow':
      return YellowCanvas;
    case 'green':
      return GreenCanvas;
    default:
      assertNever(tapeColor);
  }
};

interface SingleCanvasProps {
  children: React.ReactNode;
  isView?: boolean;
  playerID: string;
  style?: CSSProperties;
}

export const SingleCanvas = (props: SingleCanvasProps) => {
  const { children, isView } = props;
  const { G } = useGameObject();
  return (
    <div
      className={styles['single-canvas_root']}
      style={{
        backgroundImage: `url(${tapeColorToImageUrl(G.colorPalettes[props.playerID].canvas)})`,
        ...props.style,
        backgroundSize: isView ? 'cover' : 'contain',
      }}
    >
      {children}
    </div>
  );
};
