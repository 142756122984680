import { Client } from 'boardgame.io/react';
import Board from './components/Board';
import { Blanksy, BoardPropsExtended } from '@magicyard/blanksy-game/src/Game';
import { getMultiplayerMode } from '@magicyard/utils';
import React, { useEffect, useState } from 'react';
import Background from './components/Background/Background';
import { YardAndDisplayScreen } from './components/YardAndDisplayScreen';
import { YardScreen } from './YardScreen';
import { NavigationBar } from './components/navigation-bar/NavigationBar';
import { LoaderTemplate } from './components/loader-template/LoaderTemplate';
import InitialLoadingRat from './assets/loaders/InitialLoadingRat.webp';
import { assertIsDefined } from '@magicyard/utils/typeUtils';
import { identify, initAnalytics, track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { ToggleFullScreenButton } from './components/ToggleFullScreenButton';
import { AppBody } from './AppBody';
import { InQueue } from './InQueue';
import { SubmittableInput } from './components/submittable-input/SubmittableInput';
import { CONFIG } from '@magicyard/utils';
import { usePlatformController } from '@magicyard/shared/platform/hooks/usePlatformController';
import {
  YardWithDisplayController,
  YardWithQueueController,
} from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';

interface MainGameLoadingProps {
  controller: YardWithDisplayController;
}

const useReloadOnRefocus = () => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      // Reload when coming BACK into the page
      if (!document.hidden && !(document as any).webkitHidden && document.visibilityState === 'visible') {
        window.location.reload();
      }
    };
    window.document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};
const MainGameLoading = (props: MainGameLoadingProps) => {
  const { controller } = props;

  useEffect(() => {
    updateAnalyticsData({ controllerId: controller.profile.id });
  }, [controller]);

  useEffect(() => {
    track('Game Loading Appeared');
  }, []);

  return (
    <AppBody title={'Game is loading'}>
      <div></div>
    </AppBody>
  );
};

const OnInitLoading = () => {
  useEffect(() => {
    track('Init Loading Appeared');
  }, []);
  return (
    <AppBody title={''}>
      <LoaderTemplate image={InitialLoadingRat} />
    </AppBody>
  );
};

export const App = () => {
  useReloadOnRefocus();
  useEffect(() => {
    initAnalytics(CONFIG.MIXPANEL_API_KEY, 'Controller', 'Standalone', 'blanksy');
    track('App Launched');
  }, []);
  const query = new URLSearchParams(window.location.search);
  const [devState, setDevState] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      // Reload when coming BACK into the page
      if (!document.hidden && !(document as any).webkitHidden && document.visibilityState === 'visible') {
        window.location.reload();
      }
    };
    window.document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      window.document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const { toRender, title, isGame } = usePlatformController<{
    toRender: React.ReactNode;
    title?: string;
    isGame?: boolean;
  }>(
    {
      onGameLoading({ controller, communication }) {
        return {
          toRender: <MainGameLoading controller={controller} />,
        };
      },
      onInitialLoading() {
        return {
          toRender: <OnInitLoading />,
        };
      },
      onGame({ controller, onGameEnd, communication, onStartAgain, onStartAgainOnline }) {
        const urlParams = new URLSearchParams(new URL((controller.gameStartArgs as { url: string }).url).search);
        const playerID = urlParams.get('playerID');
        identify(controller.profile.id);
        assertIsDefined(playerID);
        const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';
        const serverURL = urlParams.get('serverURL') ?? undefined;

        //To support refreshes after game started.
        updateAnalyticsData({
          matchId: matchID,
          displayId: controller.displayId,
          yardId: controller.yard.id,
          controllerId: controller.profile.id,
        });
        const BgioClient = Client({
          loading: () => (
            <AppBody title={''}>
              <LoaderTemplate />
            </AppBody>
          ),
          // @ts-ignore
          game: Blanksy,
          board: (G: BoardPropsExtended) => (
            <Board
              G={G}
              onStartNewGame={() => {
                track('Play Again Clicked');
                // if (controller.yard.controllers.length >= 3) {
                onStartAgain();
                // }
                // else {
                //   onStartOnlineAgain('blanksy');
                // }
              }}
            />
          ),
          debug: devState,
          multiplayer: getMultiplayerMode(serverURL),
        });
        return {
          toRender: (
            <>
              <ToggleFullScreenButton />
              {/*<OrientationMessage />*/}
              <BgioClient playerID={playerID} matchID={matchID} />
            </>
          ),
          isGame: true,
        };
      },
      onInvalidYard({ controller, onDisplayScanned, onDisplayCodeEntered }) {
        return {
          toRender: <InvalidYard onCodeEntered={onDisplayCodeEntered} />,
          title: '',
        };
      },
      onYard({ controller, onDisplayScanned, communication }) {
        return {
          toRender: (
            <AppBody title={''}>
              {/*<ToggleMicHeader onClick={toggleMic} controller={controller} />*/}
              <YardScreen onScanDisplay={onDisplayScanned} controller={controller} />
            </AppBody>
          ),
          title: 'Scan the display',
        };
      },
      onYardWithDisplay({ controller, onSubmitOnline, onSubmitLocal, onProfileUpdate, communication }) {
        return {
          toRender: (
            <AppBody title={''}>
              {/*<ToggleMicHeader onClick={toggleMic} controller={controller} />*/}
              <YardAndDisplayScreen
                setDevState={setDevState}
                controller={controller}
                onSubmitOnline={onSubmitOnline}
                onSubmitLocal={onSubmitLocal}
                onProfileUpdate={onProfileUpdate}
              />
            </AppBody>
          ),
        };
      },
      onOnlineQueue({ controller, communication, onLeaveQueue }) {
        updateAnalyticsData({ matchId: undefined });
        return {
          toRender: <QueueContainer controller={controller} onLeaveQueue={onLeaveQueue} />,
        };
      },
    },
    { displayId: query.get('displayId'), yardId: query.get('yardId') },
    `https://avatars.dicebear.com/api/croodles/${Math.random()}.svg`
  );

  if (isGame === true) {
    return <>{toRender}</>;
  }
  return (
    <Background>
      <NavigationBar header={title} />
      {toRender}
    </Background>
  );
};

type QueueContainerProps = {
  onLeaveQueue: () => void;
  controller: YardWithQueueController;
};
const QueueContainer = (props: QueueContainerProps) => {
  const { onLeaveQueue, controller } = props;
  const queueId = controller.yard.queue.id;

  useEffect(() => {
    if (queueId === null) {
      return;
    }

    track('Queue Page Loaded', { queueId: queueId });
  }, [queueId]);

  const handleLeaveQueue = () => {
    track('Player Cancelled Queue');
    onLeaveQueue();
  };

  return (
    <AppBody title={'In queue'}>
      <InQueue onLeaveQueue={handleLeaveQueue} controller={controller} />
    </AppBody>
  );
};

const InvalidYard = ({ onCodeEntered }: { onCodeEntered: (value: string) => void }) => {
  useEffect(() => {
    track('Enter Room Code Loaded');
  }, []);

  return (
    <AppBody title={'Enter room code'}>
      <SubmittableInput
        defaultValue={''}
        type={'number'}
        inputMode={'numeric'}
        forceEnglish={false}
        onSubmit={onCodeEntered}
      />
    </AppBody>
  );
};
