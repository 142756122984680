import React, { useState } from 'react';

import styles from './Toolbar.module.css';
import BrushIcon from './assets/BrushIcon.webp';
import BackArrowIcon from './assets/BackArrowIcon.webp';
import { ColorPicker } from './ColorPicker';
import { BrushesPopover } from './brushes-popover/BrushesPopover';
import { track } from '@magicyard/shared/src/analytics';

interface ColorButtonItemProps {
  isActive: boolean;
  color: string;
  onClick: () => void;
  animationIndex?: number;
}

export const ColorButtonItem = (props: ColorButtonItemProps) => {
  return (
    <div
      className={`${styles['toolbar_color-button']}
        ${props.animationIndex !== undefined && props.isActive ? ' ' + styles['toolbar_color-button-active-enter'] : ''}
        ${props.animationIndex !== undefined && !props.isActive ? ' ' + styles['toolbar_color-button-enter'] : ''}
     `}
      style={{
        backgroundColor: props.color,
        animationDelay: props.animationIndex === undefined ? '' : props.animationIndex * 0.025 + 0.175 + 's',
        border: props.animationIndex === undefined && props.isActive ? '2px solid black' : '',
      }}
      onClick={props.onClick}
    />
  );
};

export interface ToolbarProps {
  colors: string[];
  color: string;
  width: number;
  handleColor: (currColor: string) => void;
  handleWidth: (currWidth: number) => void;
  handleSubmit: () => void;
  handleUndo: () => void;
}

export const Toolbar = ({ colors, handleUndo, handleColor, handleWidth, handleSubmit, color }: ToolbarProps) => {
  const handleBrushWidthSelect = (size: number | undefined) => {
    handleBrushPickerClick();
    if (size) {
      handleWidth(size);
      track('Drawing Brush Width Selected', { width: size });
    }
  };

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isBrushPickerOpen, setIsBrushPicker] = useState(false);

  const handleColorPickerClick = (pColor: string) => {
    if (isBrushPickerOpen) {
      setIsBrushPicker(false);
    }
    if (isColorPickerOpen) {
      handleColor(pColor);
      setIsColorPickerOpen(false);
      return;
    }
    track('Drawing Color Selected', { color: pColor });
    setIsColorPickerOpen(true);
  };
  const handleBrushPickerClick = () => {
    if (isColorPickerOpen) {
      setIsColorPickerOpen(false);
    }
    if (isBrushPickerOpen) {
      setIsBrushPicker(false);
      return;
    }

    setIsBrushPicker(true);
  };

  return (
    <div className={styles['Toolbar__wrapper']}>
      <div className={styles['Toolbar__innerBox']}>
        <div className={styles['toolbar_buttons-container']}>
          <div className={styles['Toolbar__actionButton']}>
            <div className={styles['toolbar_popover-container-color']}>
              {isColorPickerOpen ? (
                <ColorPicker
                  colors={colors}
                  activeColor={color}
                  onClick={(pColor) => handleColorPickerClick(pColor)}
                  isOpen={isColorPickerOpen}
                />
              ) : undefined}
            </div>
            <div>
              <ColorButtonItem
                key={`${color}-active-color`}
                isActive={true}
                color={color}
                onClick={() => handleColorPickerClick(color)}
              />
            </div>
          </div>

          <div className={styles['Toolbar__actionButton']}>
            <div style={{ display: 'flex' }}>
              <div className={styles['toolbar_popover-container-brush']}>
                {isBrushPickerOpen ? <BrushesPopover onClick={(size) => handleBrushWidthSelect(size)} /> : undefined}
              </div>
              <img width={'30px'} src={BrushIcon} alt="" onClick={() => handleBrushPickerClick()} />
            </div>
          </div>
          <div className={styles['Toolbar__actionButton']} onClick={handleUndo}>
            <img width={'30px'} src={BackArrowIcon} alt="" />
          </div>
        </div>
        <div className={styles['toolbar_submit']} onClick={handleSubmit} />
      </div>
    </div>
  );
};
