import React, { HTMLInputTypeAttribute, useEffect } from 'react';
import InputBackground from './assets/InputBackground.webp';
import ValidInputBackground from './assets/ValidInputBackground.webp';
import WrongInputBackground from './assets/WrongInputBackground.webp';

import styles from './SubmittableInput.module.css';

interface BrushInputProps {
  value?: string;
  defaultValue?: string;
  isValid?: boolean;
  onClick?: () => void;
  onChange?: (value: string) => void;
  onSubmit: (value: string) => void;
  style?: React.CSSProperties;
  forceEnglish: boolean;
  type?: HTMLInputTypeAttribute;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
}

export const SubmittableInput = ({
  value,
  defaultValue,
  onClick,
  style,
  onSubmit,
  onChange,
  isValid,
  forceEnglish,
  type = 'text',
  inputMode,
}: BrushInputProps) => {
  const [msg, setMsg] = React.useState('');
  const [msgTimeout, setMsgTimeout] = React.useState<number | undefined>(undefined);
  const [text, setText] = React.useState(value ?? defaultValue ?? '');

  useEffect(() => {
    return () => {
      window.clearTimeout(msgTimeout);
    };
  }, [msgTimeout]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = forceEnglish ? event.target.value.replace(/[^a-zA-Z ]/g, '') : event.target.value;
    if (event.target.value.length !== newVal.length) {
      setMsg('Only english letters are allowed');
      window.clearTimeout(msgTimeout);
      setMsgTimeout(
        window.setTimeout(() => {
          setMsg('');
        }, 1500)
      );
    } else {
      setMsg('');
      if (onChange !== undefined) {
        onChange(newVal);
      }
      setText(newVal);
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit(text);
    }
  };

  return (
    <span
      onClick={onClick}
      className={styles['submittable-input_root']}
      style={{
        ...(style ?? {}),
        backgroundImage: `url(${
          isValid === undefined ? InputBackground : isValid ? ValidInputBackground : WrongInputBackground
        })`,
      }}
    >
      <input
        inputMode={inputMode}
        autoFocus={true}
        type={type}
        value={value ?? text}
        onKeyUp={handleKeyPress}
        onChange={handleChange}
        className={styles['submittable-input_input']}
      />
      <div className={styles['submittable-input_submit']} onClick={() => onSubmit(text)} />
      <div className={styles['submittable-input_warning']}>{msg}</div>
    </span>
  );
};
