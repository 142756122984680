import React from 'react';
import { DrawingBound, Point } from '@magicyard/blanksy-game/src/Types';

/**
 * convert event to point
 */
export const toPoint = (event: React.MouseEvent): Point => {
  const { nativeEvent } = event;

  const x: number = nativeEvent.offsetX;
  const y: number = nativeEvent.offsetY;

  return { x, y };
};

interface Offsets {
  x: number;
  y: number;
}

export const touchToPoint = (
  event: React.TouchEvent,
  offsets: Offsets = {
    x: 0,
    y: 0,
  },
  bounds: DrawingBound
): Point => {
  const x: number = Math.max(0, Math.min(bounds.maxX, event.touches[0].clientX - offsets.x));
  const y: number = Math.max(0, Math.min(bounds.maxY, event.touches[0].clientY - offsets.y));

  return { x, y };
};
