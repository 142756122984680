import React, { useCallback, useEffect, useState } from 'react';
import getRandomWords from '@magicyard/blanksy-game/src/utils/assets/words';

import SentencePuzzle from '../../../components/SentencePuzzle/SentencePuzzle';
import { useGameObject } from '../../../store/GameContext';
import ControllerTransitionOverlay from '../../../components/Transition/ControllerTransitionOverlay';
import PageWrapper from '../../../components/TemplateCommon/PageWrapper';
import { useNavigationBar } from '../../../hooks/UseNavigationBar';
import { GameButton } from '../../../components/game-button/GameButton';
import IdeasButtonBackground from '../../../assets/IdeasButtonBackground.webp';
import { WordSuggestionsBox } from './components/WordSuggestionsBox';
import { SubmittableInput } from '../../../components/submittable-input/SubmittableInput';
import LoaderStageOneTV from '../../../assets/loaders/LoaderStageOneTV.webp';
import FrameBackground from '../../../assets/FrameBackground.webp';
import styles from './RoundSetupPickWord.module.css';
import { OptionalTraits, track } from '@magicyard/shared/src/analytics';

const PAGE_INFO = {
  dictionary: {
    title: 'Choose a Word',
    confirmed_title: 'Done!',
    confirmed_secondary: 'Get Ready to Draw!',
    word_suggestions: 'Give me some ideas',
  },
};
export type SubmitSource = 'input' | 'word-selection' | 'timer';
type WordSubmittedEventTraits = OptionalTraits & {
  actionSource: SubmitSource;
  word: string;
};

export const RoundSetupPickWord = () => {
  const { G, moves, playerID } = useGameObject();
  const { s00_confirmCycleCardWord } = moves;
  const { currentRoundNumber, wordForPlayer, cardSentences, players, shouldForceSubmit } = G;
  const selectedWord = wordForPlayer[playerID];
  const sentence = cardSentences[currentRoundNumber].content;
  const [word, setWord] = useState('');
  const [isConfirmedStep, setIsConfirmedStep] = useState(selectedWord !== undefined && selectedWord !== null);
  const [isWordSelection, setIsWordSelection] = useState(false);
  const isTransitionOverlay = G.isTransition;
  const [wordSelection, setWordSelection] = useState<string[]>([]);
  const { setHeader } = useNavigationBar();
  const pageHeader = isConfirmedStep ? PAGE_INFO.dictionary.confirmed_title : PAGE_INFO.dictionary.title;
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    setHeader(pageHeader);
  }, [pageHeader, setHeader]);

  const updateWordSelection = useCallback(() => {
    const cardSentenceWords = cardSentences[currentRoundNumber].words || [];
    const chunkLength = Math.floor(cardSentenceWords.length / players.length);

    const newWordSelection: Array<string> = [];
    let i = parseInt(playerID) * chunkLength;
    while (newWordSelection.length < chunkLength) {
      newWordSelection.push(cardSentenceWords[i]);
      i++;
    }
    setWordSelection([...newWordSelection, ...getRandomWords(8 - Math.min(chunkLength, 8))].slice(0, 8));
  }, [cardSentences, playerID, players.length, currentRoundNumber]);

  const onSubmit = useCallback(
    (word: string, sourceName: SubmitSource) => {
      const doesAWordHaveMoreThanMaxChars = word.split(' ').some((x) => x.length > 12);
      if (word.length >= 2 && !doesAWordHaveMoreThanMaxChars) {
        s00_confirmCycleCardWord(word);
        setIsConfirmedStep(true);
        track('Word Submitted', {
          actionSource: sourceName,
          word: word,
        });
        setErrMsg('');
      } else {
        if (word.length < 2) {
          setErrMsg('Min 2 characters');
        } else {
          setErrMsg('Max 12 chars per word');
        }
      }
    },
    [s00_confirmCycleCardWord, setIsConfirmedStep]
  );

  useEffect(() => {
    if (!shouldForceSubmit || isConfirmedStep) {
      return;
    }
    console.log('Force submitting with', wordSelection[0]);
    onSubmit(wordSelection[0], 'timer');
  }, [isConfirmedStep, onSubmit, shouldForceSubmit, wordSelection]);

  useEffect(() => {
    if (!wordSelection.length) {
      updateWordSelection();
    }
  }, [updateWordSelection, wordSelection]);

  const pickWord = (newWord: string) => {
    if (newWord.trim().length >= 2) {
      setIsWordSelection(false);
    }
    setErrMsg('');
    setWord(newWord.toUpperCase());
  };

  const onBack = () => {
    if (!isConfirmedStep) {
      setIsWordSelection(false);
    }
  };

  const handleSuggestionBoxSelect = (sWord: string) => {
    track('Suggestion Word Selected', { word: sWord });
    pickWord(sWord);
  };
  const handleSuggestionsOpen = () => {
    track('Suggestion Word Box Opened');
    setIsWordSelection(true);
  };

  const userInputSection = () => {
    return !isWordSelection ? (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            marginTop: 20,
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <SubmittableInput
            isValid={errMsg === '' ? undefined : false}
            forceEnglish={true}
            value={selectedWord ?? word}
            onChange={(value) => pickWord(value)}
            onSubmit={() => onSubmit(word, 'input')}
          />
          {errMsg !== '' && <div style={{ color: 'red', marginTop: -20, fontSize: 24 }}>{errMsg}</div>}
        </div>
        <div>
          <GameButton
            style={{ minHeight: '74px' }}
            background={IdeasButtonBackground}
            onClick={handleSuggestionsOpen}
            text={PAGE_INFO.dictionary.word_suggestions}
          />
        </div>
      </div>
    ) : (
      <>
        <WordSuggestionsBox
          onSelect={(sWord) => handleSuggestionBoxSelect(sWord)}
          wordSelection={wordSelection}
          onExit={() => setIsWordSelection(false)}
        />
      </>
    );
  };

  if (isTransitionOverlay) {
    return <ControllerTransitionOverlay show={isTransitionOverlay} image={LoaderStageOneTV} />;
  }

  return (
    <PageWrapper>
      <div className={styles['pick-word_root']}>
        <div className={styles['pick-word_container']}>
          {isConfirmedStep ? (
            <ConfirmedView
              onBackClick={() => {
                s00_confirmCycleCardWord(null);
                setIsConfirmedStep(false);
                setWord('');
              }}
              sentence={sentence}
              word={selectedWord ?? word}
            />
          ) : (
            <div className={styles['pick-word_sentence-puzzle-container']}>
              <SentencePuzzle sentence={sentence} word={selectedWord ?? word} isVisibleWord={true} />
            </div>
          )}
        </div>
        {!isConfirmedStep ? userInputSection() : undefined}
      </div>
    </PageWrapper>
  );
};

const ConfirmedView = (props: { sentence: string; word: string; onBackClick: () => void }) => {
  return (
    <>
      <div
        className={`${styles['pick-word_confirmed-view-container']} ${styles['pick-word_sentence-puzzle-container']}`}
        style={{
          backgroundImage: `url(${FrameBackground})`,
        }}
      >
        <div className={styles['round-setup-pick-word_sentence-view']}>
          <div>"</div>
          <SentencePuzzle sentence={props.sentence} word={props.word} isVisibleWord={true} isView={true} />
          <div>"</div>
        </div>
      </div>
      <GameButton text={'Edit word'} onClick={props.onBackClick} />
    </>
  );
};
